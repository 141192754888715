import React, { useReducer, createContext } from 'react'
import AppReducer from './AppReducer'
import { CanvasMode, FrameBreakpoints } from '../pages/project/[pid]'
import { getProxyURL } from './helpers'
import { useRouter } from 'next/router'
import { sendMessageToIframe } from './sendMessageToIframe'

export interface State {
  canvasMode: CanvasMode
  iframeBreakpoint: FrameBreakpoints
  setCanvasMode: (mode: CanvasMode) => void
  setIframeBreakpoint: (breakpoint: FrameBreakpoints) => void
}

export type Action =
  | { type: 'SET_CANVAS_MODE'; payload: CanvasMode }
  | { type: 'SET_IFRAME_BREAKPOINT'; payload: FrameBreakpoints }

// @ts-ignore
const initialState: State = {
  canvasMode: 'browse',
  iframeBreakpoint: 'desktop',
}

export const GlobalContext = createContext(initialState)

export const GlobalProvider = (props: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  // Actions for changing state

  function setCanvasMode(mode: CanvasMode) {
    dispatch({
      type: 'SET_CANVAS_MODE',
      payload: mode,
    })
  }
  function setIframeBreakpoint(breakpoint: FrameBreakpoints) {
    sendMessageToIframe({
      type: 'uiAction',
      action: 'modeChange',
      data: { screenSize: breakpoint },
    })
    dispatch({
      type: 'SET_IFRAME_BREAKPOINT',
      payload: breakpoint,
    })
  }

  return (
    <GlobalContext.Provider
      value={{
        canvasMode: state.canvasMode,
        iframeBreakpoint: state.iframeBreakpoint,
        setCanvasMode,
        setIframeBreakpoint,
      }}
      {...props}
    />
  )
}
