import Head from 'next/head'
import { useRouter } from 'next/router'

interface Props {
  meta: {
    title: string
    description: string
    cardImage: string
  }
}

const PageMeta = ({ meta }: Props) => {
  const router = useRouter()
  return (
    <Head>
      <title>{meta.title}</title>
      <meta name="robots" content="follow, index" />
      <link href="/favicon.ico" rel="shortcut icon" />
      <meta content={meta.description} name="description" />
      <meta property="og:url" content={`https://subscription-starter.vercel.app${router.asPath}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={meta.cardImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@vercel" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.cardImage} />
    </Head>
  )
}

export default PageMeta
