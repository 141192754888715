export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ''
      ? process.env.URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
      ? process.env.VERCEL_URL
      : 'http://localhost:3000'
  return url.includes('http') ? url : `https://${url}`
}
interface PostData {
  url: string
  token: string
  data?: Object
}

export const postData = async ({ url, token, data = {} }: PostData) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
    body: JSON.stringify(data),
  })

  if (!res.ok) {
    throw Error
  }

  return res.json()
}

export const toDateTime = (secs: number) => {
  var t = new Date('1970-01-01T00:30:00Z') // Unix epoch start.
  t.setSeconds(secs)
  return t
}

export function isValidHttpUrl(string: string) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(string)
}

export function URLtitle(str: string) {
  const splitStr = str.split('/')
  const title = splitStr[2]
  return title ?? str
}

export const getProxyURL = (pid: string) =>
  process.env.NODE_ENV === 'development'
    ? `http://${pid}.p.subdomain.test:4333`
    : `https://${pid}.p.${process.env.NEXT_PUBLIC_PROXY_BASE_URL}`

export const baseURL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.NEXT_PRODUCTION_URL
