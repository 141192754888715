import { Box } from '@chakra-ui/layout'
import { LayoutProps } from './Layout'
import PageMeta from './PageMeta'
import Navbar from './ui/Navbar'

const ProjectLayout = ({ children, meta: pageMeta }: LayoutProps) => {
  const meta = {
    title: 'HyperFeedback App',
    description: 'Brought to you by Vercel, Stripe, and Supabase.',
    cardImage: '/og.png',
    ...pageMeta,
  }

  return (
    <>
      <PageMeta meta={meta} />
      <Navbar />
      <Box as="main" id="skip" overflowY="hidden">
        {children}
      </Box>
    </>
  )
}

export default ProjectLayout
