import { State, Action } from './useUIConfig'

export default (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CANVAS_MODE':
      return {
        ...state,
        canvasMode: action.payload,
      }
    case 'SET_IFRAME_BREAKPOINT':
      return {
        ...state,
        iframeBreakpoint: action.payload,
      }
    default:
      return state
  }
}
