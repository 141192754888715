import '../styles/globals.css'
import React from 'react'
import type { AppProps } from 'next/app'
import { UserContextProvider } from '@utils/useUser'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { ReactQueryDevtools } from 'react-query/devtools'
import { theme } from 'chakra-theme'
import { useRouter } from 'next/router'
import MainLayout from '@components/Layout'
import ProjectLayout from '@components/ProjectLayout'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import 'focus-visible/dist/focus-visible'
import { GlobalProvider } from '@utils/useUIConfig'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})
export default function MyApp({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter()

  if (typeof window !== 'undefined') {
    const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage })
    persistQueryClient({
      queryClient,
      persistor: localStoragePersistor,
    })
  }

  return (
    <div className="bg-primary">
      <UserContextProvider>
        <GlobalProvider>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
              {pathname === '/project/[pid]' ? (
                <ProjectLayout>
                  <Component {...pageProps} />
                </ProjectLayout>
              ) : (
                <MainLayout>
                  <Component {...pageProps} />
                </MainLayout>
              )}
            </ChakraProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </GlobalProvider>
      </UserContextProvider>
    </div>
  )
}
