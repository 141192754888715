import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    brand: {
      darkGreen: '#09333F',
      lightGreen: '#009D9A',
      lighterGreen: '#26F7B2',
      bgColor: '#D3EDEA',
      bgGrey: '#F9FAFB',
    },
  },
  styles: {
    global: {
      /*
        This will hide the focus indicator if the element receives focus    via the mouse,
        but it will still show up on keyboard focus.
      */
      '.js-focus-visible :focus:not([data-focus-visible-added])': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
})
