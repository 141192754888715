import { useRouter } from 'next/router'

import Navbar from '@components/ui/Navbar'
import Footer from '@components/ui/Footer'
import { ReactChild, useEffect } from 'react'
import PageMeta from './PageMeta'

export interface LayoutProps {
  children: ReactChild
  meta?: Record<string, unknown>
}

export default function Layout({ children, meta: pageMeta }: LayoutProps) {
  const router = useRouter()
  const meta = {
    title: 'HyperFeedback | Get Clients Feedback Changes Seamlessly ',
    description:
      'Allow your client to add comments and text directly on the website that you are working on',
    cardImage: '/og.png',
    ...pageMeta,
  }
  const NoNavbar = ['/signin', '/signup', '/forgot-password']
  return (
    <>
      <PageMeta meta={meta} />
      {!NoNavbar.includes(router.pathname) && <Navbar />}
      <main id="skip">{children}</main>
    </>
  )
}
