import { useUser } from '@utils/useUser'
import {
  Box,
  Flex,
  VisuallyHidden,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
} from '@chakra-ui/react'
import { HiChevronDown } from 'react-icons/hi'
import { Logo } from './Logo'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface NavItem {
  href: string
  content: string
}

const navigation: NavItem[] = [
  {
    href: '/dashboard',
    content: 'My Dashboard',
  },
]

const Navbar = () => {
  const { user, signOut, userDetails } = useUser()
  const router = useRouter()

  const handleSignout = () => {
    signOut()
    router.replace('/signin')
  }

  return (
    <nav>
      <VisuallyHidden>Skip to content</VisuallyHidden>
      <Box mx={'auto'} maxW="6xl" px="6">
        <Flex justifyContent="space-between" alignItems="center" pos="relative">
          <Flex alignItems="center" flex={1}>
            <ChakraLink as="span" mr={10} href="/">
              <Logo height={100} width={100} />
            </ChakraLink>
            <Box>
              {navigation.map(({ href, content }, idx) => {
                return (
                  <ChakraLink as="span" mr={3} key={idx}>
                    <a href={href}>{content}</a>
                  </ChakraLink>
                )
              })}
            </Box>
          </Flex>

          <Box>
            {user ? (
              <Menu placement="bottom-start">
                <MenuButton
                  as={Button}
                  rightIcon={<HiChevronDown />}
                  display="flex"
                  height="full"
                  alignItems="center"
                  bg="transparent"
                  _hover={{ bg: 'transparent', outline: 'none' }}
                  _focus={{ bg: 'transparent', outline: 'none' }}
                  _active={{ bg: 'transparent', outline: 'none' }}
                >
                  <Avatar name={userDetails?.full_name} />
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <Link href="/account">Account Setting</Link>
                  </MenuItem>
                  <MenuItem onClick={handleSignout}>Sign out</MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Link href="/signin">
                <a>Sign in</a>
              </Link>
            )}
          </Box>
        </Flex>
      </Box>
    </nav>
  )
}

export default Navbar
