import { getProxyURL } from '@utils/helpers'
import { isUUID } from '@utils/isUUID'

export interface ActionEvents {
  type: 'uiAction' | 'dataSyncAction'
  action: 'repositionPins' | 'addedComment' | 'syncComments' | 'modeChange' | 'focus'
  data?: any
}

export const sendMessageToIframe = (message: ActionEvents) => {
  const [, , pid] = location.pathname.split('/')
  console.log('sendMessageToIframe', pid)
  if (isUUID(pid)) return console.error('pid is not a uuid')
  console.log('sending message to iframe', message)
  document.querySelector('iframe')?.contentWindow?.postMessage(message, getProxyURL(pid))
}
