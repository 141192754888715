import { Provider, Session, Subscription, User, UserCredentials } from '@supabase/supabase-js'
import { definitions } from '../types/supabase'
import { useEffect, useState, createContext, useContext } from 'react'
import { supabase } from './supabase-client'

interface UserContext {
  session: Session
  user: User
  userDetails: definitions['users']
  userLoaded: boolean
  isTeamAdmin: boolean
  subscription: any
  signIn: (
    UserCredentials: UserCredentials,
    options?: { scopes?: string; redirectTo?: string },
  ) => Promise<{
    session: Session | null
    user: User | null
    provider?: Provider
    url?: string | null
    error: Error | null
    data: Session | null
  }>
  signUp: (
    UserCredentials: UserCredentials,
    options?: { data?: any; redirectTo?: string },
  ) => Promise<{
    user: User | null
    session: Session | null
    error: Error | null
    data: Session | User | null
  }>
  signOut: () => Promise<{ error: Error | null }>
}

export const UserContext = createContext<Partial<UserContext>>({})

export const UserContextProvider = (props: any) => {
  const [userLoaded, setUserLoaded] = useState(false)
  const [session, setSession] = useState<Session | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [subscription, setSubscription] = useState(null)
  const [isTeamAdmin, setIsTeamAdmin] = useState(false)

  useEffect(() => {
    const session = supabase.auth.session() as Session
    console.log('current User: ', session?.user)
    setSession(session)
    setUser(session?.user ?? null)
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session)
      setUser(session?.user ?? null)
      fetch('/api/auth', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin',
        body: JSON.stringify({ event, session }),
      }).then((res) => res.json())
    })

    return () => {
      authListener?.unsubscribe()
    }
  }, [])

  const getUserDetails = () =>
    supabase.from<definitions['users']>('users').select('*').eq('id', user?.id).single()

  const getSubscription = () =>
    supabase
      .from<definitions['subscriptions']>('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .single()

  useEffect(() => {
    if (user) {
      Promise.allSettled([getUserDetails(), getSubscription()]).then((results: any) => {
        results.forEach((result: any) => {
          if (result.status === 'fulfilled') {
            const [users, subscription] = results
            setUserDetails(users?.value?.data)
            setSubscription(subscription?.value?.data)
            setUserLoaded(true)
            setIsTeamAdmin(users?.value?.data?.role === 'team_admin')
          }
        })
      })
    }
  }, [user])

  const value = {
    session,
    user,
    userDetails,
    userLoaded,
    isTeamAdmin,
    subscription,
    signIn: (
      UserCredentials: UserCredentials,
      options?: { scopes?: string; redirectTo?: string },
    ) => supabase.auth.signIn(UserCredentials, options),
    signUp: (UserCredentials: UserCredentials, options?: { data?: any; redirectTo?: string }) =>
      supabase.auth.signUp(UserCredentials, options),
    signOut: () => {
      setUserDetails(null)
      setSubscription(null)
      return supabase.auth.signOut()
    },
  }
  return <UserContext.Provider value={value} {...props} />
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context as UserContext
}
